.auth-modal-body {
	margin: 50px 0;
	font-weight: 600;

	.modal-logo {
		margin-bottom: 40px;
	}
	.fb-btn {
		max-height:60px;
	}
	.nav-tabs {
		display: none;
	}

	div[trio-login-form],
	div[flx-register-form],
	div[flx-forgot-form] {
		margin: 0 0 40px;
	}

	p {
		margin-bottom: 10px;
	}

	// button[type="submit"] {
	// 	margin-top: 30px;
	// }

}