.modal-dialog {
	&.modal-sub-plans {
		@include page-modal-dialog(1100px, 80%);
	}
}


.sub-plans-modal {
	margin: 50px 0;
	font-weight: 600;
	padding: 0;

	.plan-heading {
		font-size: 50px;
		text-align: center;
		color: $light;
		margin-bottom: 20px;
	}
	.plan-sub-heading {
		text-align: center;
		font-size: 22px;
		color: $light;
		margin-bottom: 40px;
	}

	div[flx-sub-plans-table] {
		@include clearfix();
	}
}




/* Responsive styles
-------------------------------------------------------------- */

/* Small devices (tablets, 768px and down) */
@media (max-width: $screen-sm) {

	.sub-plans-modal {
		.plan-heading {
			font-size: 36px;
		}
		.plan-sub-heading {
			font-size: 22px;
		}
	}

}

/* Extra small devices (phones, less than 480px) */
@media (max-width: $screen-xs) {

	.sub-plans-modal {
		.plan-heading {
			font-size: 28px;
		}
	}

}


