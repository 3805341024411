.bank-card-form {
	input[type="text"],
	input[type="email"],
	input[type="password"] {
		margin-bottom: 10px;
	}
	.checkbox {
		margin-bottom: 15px;
	}
	button {
		width: 100%;
		max-width: 170px;
		margin-bottom: 15px;
	}
	.card-number {
		max-width: 170px;
	}
	.card-cvc {
		max-width: 60px;
	}
	.card-exp-input {
		max-width: 50px;
		display: inline-block;
	}
}