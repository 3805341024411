#ie-page {

	font-size: 16px;

	div[ui-view="main"] {
		display: table;
		width: 100%;
		height: 100%;
	}

	#initial-loading {
		position: relative;
		display: table-cell;
		vertical-align: middle;
		text-align: center;

		> img {
			display: none;
		}
	}

	.fa-spinner {
		display: none;
	}

	#ie-message {
		display: block;
	}

	#ie-msg-logo {
		margin-bottom: 30px;
	}

	#browser-link {
		img {
			width: 200px;
			height: 200px;
		}
	}
}