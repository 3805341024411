#live-page {

	@include full-window-page();

	#live-content {
		height: 100%;
		margin: 0 auto;
		overflow: hidden;
		background: #000;
	}

	#col-player {
		height: 100%;
	}

	#col-channels {
		height: 100%;
		overflow-y: auto;
	}

	div[flx-simultaneous-shows] {
		margin: 10px 0 20px;
	}


	@media (max-width: $grid-float-breakpoint-max) {
		#col-player {
			display: none;
		}
	}

}

