// Colors
$main-color: #000;
$text-color: #666;
$light: #fff;
$dark: 	#333;
$link-color: #ed1c24; // overwrites the Bootstrap variable. Change the name??
$link2-color: #00a651;


// Sizes
$top-bar-height: 60px;


// font-awesome
// for referencing Bootstrap CDN font files directly
$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/4.5.0/fonts" !default;

