#search-page {
	.page-header {
		span {
			font-weight: bold;
		}
	}

	div[ui-view="main"] {
		padding-bottom: 70px;
	}

	a[flx-show-thumb] {
		max-width: 275px;
		margin-bottom: 20px;
		background: #000;

		&:hover {
			background-color:#252525;
		}
	}
}