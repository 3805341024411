.player-modal-window {}

.modal-dialog {
	&.modal-player {
		@include page-modal-dialog();
		display: block;

		.modal-content {
			display: block;
			height: 100%;
		}
	}
}


.player-modal-body {
	height: 100%;
	padding-top: $top-bar-height;
	font-weight: 600;

	.modal-close-btn {
		top: 0;
		right: 0;
	}

}

.player-modal-backdrop {}