#personal-details-page {

	#details-form {
		max-width: 500px;

		label {
			white-space: nowrap;
		}

		button {
			min-width: 150px;
		}
	}

}