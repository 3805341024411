.loading-overlay {
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: #f8f8f8;
	i {
		font-size: 30px;
		margin-left: -15px;
		@include absolute-center();
	}
}