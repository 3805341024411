.flowplayer {

	.fp-ratio {
	  //  padding-top: 41.67%;
	}

	&.is-loading {

		.fp-waiting {
			margin: 0;
			@include absolute-center();
		}
	}
}
