.nav-sidebar {
	background: #000;
	li {
		&.active {
			a {
				background: #333;
			}
		}
		a {
			color: #f8f8f8;
			&:hover {
				background: #444;
			}
		}
	}
}
