$home-tab-height: 174px;

#home-page {
	background: $main-color;

	/* Main Menu
	-------------------------------------------------------------- */
	#main-nav {
		position: absolute;
		top: 30px;
		border: 0;
		background: transparent;

		.action-call-btn,
		.login-btn {
			text-transform: none;
			padding: 10px 30px;
		}

		.action-call-btn {
			background: $link2-color;
			display: none;
		}
		.login-btn {
			background: $link-color;
		}
	}

	#logo {
		&:hover {
			opacity: 0.7;
		}
	}


	/* Welcome Screen
	-------------------------------------------------------------- */
	.welcome-screen {
		height:100vh;
		width:100%;
		padding-top: 300px;
		color: $light;
		display:block;
		text-align: center;
		position: relative;

		background: rgba(0,0,0,0.5);

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
		background: -moz-linear-gradient(top,  rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

		h1 {
			margin-top: 0;
			font-size: 50px;
			line-height: 44px;
			font-weight: 400;
			position: absolute;
			top: 50%;
			margin-top: -150px;
			width: 100%;

			small {
				color: #fff;
				font-size: 24px;
				&:before {
					content: "\A";
					display: block;
				}
			}
		}

		.call-to-action {
			position: absolute;
			bottom: 190px;
			width: 100%;

			.btn {
				&:hover {
					background: $main-color;
				}
			}

			.signup-btn {
				border: 0;
				background: $link-color;
				color: $light;
				font-size: 24px;
				margin-top: 20px;
			}

			.more-btn {
				border: 0;
				background: transparent;
				color: $light;
				font-size: 18px;
				font-style: italic;
				margin-top: 20px;
			}
		}

	}


	/* Tabs
	-------------------------------------------------------------- */
	.tabs {
		width:100%;
		background: #252525;
		color: #fff;
		display:block;
		z-index: 2;
		margin-top: -$home-tab-height;

		.nav-tabs {
			background: #fff;
			text-align: center;

			> li {
				display: inline-block;
				padding: 20px 0 4px 0;
				width: 25%;
				max-width: 300px;
				height: $home-tab-height;
				max-height: $home-tab-height;
				float: none;
				background: url(/images/tab-delimeter.png) right 20px no-repeat;

				> a {
					padding-top: 20px;
					border: none !important;
					color: #666666;
					font-size: 14px;

					img {
						margin-bottom: 10px;
						opacity: 0.6;
						max-width: 100%;
					}

					&:hover {
						color: #333;
						background: transparent;

						img {
							opacity: 1;
						}
					}
				}

				&.active {
					a {
						background: url(/images/tab-active.png) center top no-repeat !important;
						background-size: 199px 1px !important;
						color: #333;

						img {
							opacity: 1;
						}
					}
				}

				&:last-child {
					background: none;
				}
			}
		}

		.container {
			padding: 30px 0 50px 0;

			.devices {
				margin: 30px auto 50px auto;
			}
		}
	}


	/* Subscription Categories
	-------------------------------------------------------------- */
	.sub-cat-table {
		max-width: 800px;
		margin: 70px auto 40px auto;
	}


	/* EPG
	-------------------------------------------------------------- */
	div[trio-epg] {
		border-bottom: 2px solid #000;
	}

	.epg-channel {
		height: $epg-row-height-lg;
		padding-top: 21px;
		text-indent: 0;
		line-height: 1;
		font-size: 16px;
		text-align: center;
		background-image: none !important;
		color: #333;

		&:hover {
			button {
				display: none;
			}
		}
	}

	.epg-time-frame {
		background: #000;
	}

	.epg-body {
		height: 500px;
		overflow-y: auto;
	}

	.epg-prev,
	.epg-next {
		height: 500px;
	}


	/* Footer
	-------------------------------------------------------------- */
	footer {
		height: 100px;
		background: #111;
	}


	/* Responsive styles
	-------------------------------------------------------------- */

	@media (min-width: $screen-sm-min) {
		#main-nav {

			// When the top bar is sticked to the top
			&.is-sticky {
				background: #fff;
				box-shadow: 0 0 10px rgba(0,0,0,0.4);

				#logo {
					background-image: url(/images/logo-bgflix-invert.png);
				}

				.navbar-nav {
					li {
						a {
							display: none;

							&.action-call-btn,
							&.login-btn {
								display: block;
							}
						}
					}
				}
			}
		}
	}

	/* Medium devices (desktops, 992px and down) */
	@media (max-width: $screen-md-min) {

		#main-nav {

			.navbar-nav {
				li {
					a {
						padding: 10px;
					}
				}
			}

		}

		.sub-cat-table {
			margin: 50px auto;
		}

		.welcome-screen {
			h1 {
				font-size: 36px;
				line-height: 30px;
				margin-top: -150px;
			}
			.call-to-action {
				.more-btn {
					margin-top: 10px;
				}
			}
		}

		.tabs {
			.nav-tabs {
				> li {
					width: 22%;

					&.active {
						a {
							background-size: 84% 1px !important;
						}
					}
				}
			}
			.container {
				padding: 0 2%;

				img {
					max-width: 96%;
				}
			}
		}
	}

	/* Small devices (tablets, 768px and down) */
	@media (max-width: $screen-sm-min) {

		#main-nav {
			position: absolute !important;

			.navbar-nav {
				li {
					a {
						font-size: 16px;
					}
				}
			}
		}

		.welcome-screen {
			h1 {
				font-size: 26px;
				line-height: 1;
				margin-top: 0;
				top: 100px;

				small {
					font-size: 18px;
				}
			}
			.call-to-action {
				.signup-btn {
					font-size: 20px;
					padding: 8px 16px;
				}
				.more-btn {
					font-size: 16px;
					padding: 4px 10px;
				}
			}
		}

		.tabs {
			.nav-tabs  {
				> li {
					width: 22%;
				}
			}
		}
	}

	/* Extra small devices (phones, less than 480px) */
	@media (max-width: $screen-xs-min) {

		#main-nav {
			top: 0;
		}

		.welcome-screen {
			h1 {
				font-size: 24px;
				top: 80px;

				small {
					font-size: 16px;
				}
			}
			.call-to-action {
				.signup-btn {
					font-size: 18px;
					padding: 4px 16px;
				}
				.more-btn {
					font-size: 14px;
					padding: 4px 10px;
				}
			}
		}

		.tabs {
			margin-top: -130px;

			.nav-tabs  {
				> li {
					width: 24%;
					a {
						font-size: 12px;
					}
				}
			}

		}
	}

}