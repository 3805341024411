// http://codemug.com/html/custom-scrollbars-using-css/
// https://davidwalsh.name/custom-scrollbars
// https://css-tricks.com/custom-scrollbars-in-webkit/
// https://stackoverflow.com/questions/9251354/css-customized-scroll-bar-in-div
// JS https://github.com/noraesae/perfect-scrollbar


// https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/
// Mixin to customize scrollbars
// Beware, this does not work in all browsers
// @author Hugo Giraudel
// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
// @param {Color} $foreground-color - Scrollbar's color
// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
// @example scss - Scrollbar styling
// @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
	// For Google Chrome
	::-webkit-scrollbar {
			// width:  $size;
			// height: $size;
	}

	::-webkit-scrollbar-thumb {
			background: $foreground-color;
	}

	::-webkit-scrollbar-track {
			background: $background-color;
	}

	::-webkit-scrollbar-thumb:window-inactive { background: rgba(255,0,0,0.4); }

	// For Internet Explorer
	body {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}