.logged-page {
	background: $main-color url(/images/bg-girl-hand-phone.jpg) center center no-repeat;
	background-size: cover;
	background-attachment: fixed;

	padding-top: $top-bar-height;

	#logo {
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}


	/* Responsive styles
	-------------------------------------------------------------- */

	/* Small devices (tablets, 768px and down) */
	@media (max-width: $screen-xs-max) {

		padding-top: 0;

		#main-nav {
			.navbar-collapse {
				border-top: 0;
				box-shadow: none;
			}

			#logo {
				z-index: -1;
			}

			.navbar-toggle {
				float: none;
				display: block;
				width: 100%;
				height: 50px;
				padding: 0 0 0 10px;
				margin: 0;
				border: 0;
				background: transparent;

				.icon-bar {
					// display: none;
				}
			}
		}
	}

}
