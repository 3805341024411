form {
	button {
		border-color: #fff;
	}

	input,
	button.btn {
		font-size: 20px;
		border: 0;
	}

	button.btn {
		border: 2px solid $light;
		margin-bottom: 5px;
	}
}

.default-form {
	background: #151515;
	padding: 20px;
}


.input-group {
	width: 100%;
}

.input-group-addon {
	border: 0;
	min-width: 40px;
}

.has-error{
	.input-group-addon {
		background: $link-color;
		color: $light;
	}
	&.checkbox {
		a {
			color: $state-danger-text !important;
		}
	}
}

.btn {
	i {
		margin-right: 5px;
	}
}


.ng-valid       {  }
.ng-invalid     {  }
.ng-pristine    {  }
.ng-dirty       {  }
.ng-touched     {  }

/* really specific css rules applied by angular */
.ng-invalid-required        {  }
.ng-invalid-minlength       {  }
.ng-valid-max-length        {  }



// input.ng-touched.ng-invalid,
// input.ng-touched.ng-invalid-required {
// 	border-color: red;
// }



.auth-form {
	// input[type="text"],
	// input[type="email"],
	// input[type="password"] {
	// 	width: 100%;
	// 	margin-bottom: 10px;
	// }

	.checkbox {
		margin-bottom: 15px;

		& + .checkbox {
			margin-top: -10px;
		}
	}

	// button {
	// 	width: 100%;
	// 	margin-bottom: 15px;
	// }

	p {
		margin-bottom: 10px;
	}
}