.landing-image-background {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	transform: translateX(-50%) translateY(-50%);
	background: url('/images/videobg.jpg') right center no-repeat;
	background-size: cover;
	transition: 1s opacity;
}
.landing-video-background {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	transform: translateX(-50%) translateY(-50%);
	background-size: cover;
	transition: 1s opacity;
}