.profile-page {

	.page-header {
		font-size: 27px;
		i {
			margin-right: 10px;
		}
	}

	.main {
		position: relative;
	}


	/* Responsive styles
	-------------------------------------------------------------- */

	/* Small devices (tablets, 768px and down) */
	@media (max-width: $screen-xs-max) {
		.sidebar {
			li {
				display: inline-block;
			}
		}
	}

}



@import "credentials/credentials";
@import "devices/devices";
@import "paymentMethods/paymentMethods";
@import "personalDetails/personalDetails";
@import "subscriptions/subscriptions";
