.table {
	background: #181818;

	> thead,
	> tbody {
		> tr {
			> th,
			> td {
				border-top: 1px solid #282828;
			}
		}
	}
}

.table-striped {
	> tbody {
		>tr:nth-of-type(odd) {
			background: #080808;
		}
	}
}
