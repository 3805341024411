$epg-hours: 26;
$epg-time-mark-width: 2px;
$epg-space-between-rows: 4px;

// Left sidebar width
$channels-width-xxs: 40px;
$channels-width-xs: 100px;
$channels-width-sm: 140px;
$channels-width-lg: 160px;

// Rows height
$epg-row-height-xxs: 60px; //80px
$epg-row-height-xs: 60px;
$epg-row-height-sm: 60px;
$epg-row-height-lg: 60px;

// 1 hour width
$hour-width-xxs: 140px;
$hour-width-xs: 160px;
$hour-width-sm: 200px;
// $hour-width-lg: 300px;
$hour-width-lg: 200px;


// Colors
$time-mark-color: #636363;
$live-show-color: #9e005d;
$past-show-color: #252525;