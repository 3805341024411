.nav {
	li {
		a {
			i {
				margin-right: 7px;
			}
		}
	}
}


#main-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	height: $top-bar-height;
	background: #111;
	border-bottom: 1px solid #555;

	.modal-open & {
		z-index: 1039; // Move below the modal backdrop
		right: 15px;
	}

	.container-fluid {
		max-width: 1200px;
	}

	.navbar-collapse {
		padding: 0;
		margin: 10px 0 0;

		form {
			margin: 0;
			input {
				// border-right: 0;
				border: 0;
				height: 40px;
				font-size: 16px;
				line-height: 40px;
				padding: 0 10px;
			}
			.btn {
				font-size: 20px;
				// border: 1px solid #ccc;
				border: 0;
				margin: 0;
				padding: 0;
				width: 40px;
				height: 40px;
				i {
					margin: 0;
				}
			}
			button[type="button"] {
				border: 1px solid #555;
				background: #000;
				color: #ccc;
			}
		}
	}

	.navbar-nav {
		line-height: 1;

		li {
			a {
				color: #f8f8f8;
				background: transparent;
				font-size: 20px;
				padding: 10px 15px;
				text-transform: uppercase;
				@extend %noselect;

				&:hover {
					background:$main-color;
				}
			}

		}
	}

	.navbar-right {
		margin-right: 0;
		li {
			a {
				text-transform: none;
			}
		}
	}

	.dropdown-menu {
		background: $dark;
		min-width: 180px;
		margin-top: 10px;

		li {
			a {
				color: #fff;
				font-size: 16px;
				padding: 7px 8px;
				text-transform: uppercase;
			}
		}
	}

	.navbar-toggle {
		background-color: #222;
	}

	// Avatar of the user in the menu
	// .avatar {
	// 	float: right;
	// 	margin-top: -10px;
	// 	margin-right: 10px;
	// }

}

// Brand
#logo {
	display: block;
	width: 150px;
	height: 0;
	padding: 80px 0 0;
	margin: 0;
	text-indent: -9999px;
	line-height: 0;
	font-size: 0;
	background: url(/images/logo-bgflix.png) center center no-repeat;
	float: none;
	@include absolute-center();
	transition: all 0.3s ease-in-out;
}


/* Responsive styles
-------------------------------------------------------------- */

@media (min-width: $screen-sm-min) {

	#main-nav {
		.navbar-collapse {
			form {
				transition: all 0.3s ease-in-out;

				input,
				button[type="submit"] {
					display: none;
				}
			}
			.show-search {
				input,
				button[type="submit"] {
					display: block;
				}
				button[type="button"] {
					display: none;
				}
			}
		}
	}
}

/* Small devices (tablets, 768px and down) */
@media (max-width: $screen-xs-max) {

	#main-nav {
		position: relative;
		height: 50px;

		.navbar-collapse {
			margin: 0 -15px;
			background: #333;
			max-height: initial;

			&.in {
				overflow: hidden;
			}

			form {
				button[type="button"] {
					display: none;
				}
			}
		}
		.navbar-nav {
			margin-left: 0;
			margin-right: 0;
			li {
				a {
					padding: 10px;
					text-align: center;
				}
			}
		}
	}

	#logo {
		padding: 50px 0 0;
	}
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: $screen-xs-min) {

}
