@mixin absolute-center($translateY: -50%) {
	position: absolute;
	top: 50%;
	left: 50%;
	@include translate3d(-50%, $translateY, 0);


	// hacks for smooth fonts and lines

	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;

	// -webkit-perspective: 1000;
	// -moz-perspective: 1000;
	// -ms-perspective: 1000;
	// perspective: 1000;
}
