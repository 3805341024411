.page-modal-window {
	.modal-content {
		box-shadow: none;
		background: transparent;
		border: 0;
	}
}


.page-modal-backdrop {
	&.in {
		opacity: 1;
	}
}

@mixin page-modal-dialog($max-width: 100%, $height: 100%) {
	display: table;
	margin: 0 auto;
	width: 100%;
	max-width: $max-width;
	height: $height;

	color: #fff;

	.modal.in &,
	.modal.fade & {
		-webkit-transform: none;
		transform: none;
	}
	.modal.fade & {
		opacity: 0;
		transition: all 0.3s ease-in-out;
	}
	.modal.in & {
		opacity: 1;
	}

	.modal-content {
		display: table-cell;
		vertical-align: middle;

		@extend %noselect;
	}

	a {
		color: #fff;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	@media (max-width: $max-width + 55) {

		.modal-close-btn {
			right: 5px;
		}
	}
}

.modal-close-btn {
	display: block;
	width: 55px;
	line-height: 55px;
	font-size: 35px;
	text-align: center;
	color: #999 !important;
	position: absolute;
	top: -35px;
	right: -25px;
	&:hover {
		color: orange !important;
	}
}


// Auth modals - Login, Register, Forgot Pass
.modal-dialog {
	&.modal-auth {
		@include page-modal-dialog(380px, 80%);

		text-align: center;

		input[type="text"],
		input[type="password"],
		input[type="email"] {
			text-align: center;
		}
	}
}



