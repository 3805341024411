#subscribe-page {

	.container {
		position: relative;
		padding-bottom: 50px;
	}

	.sub-col {
		// width: 100%;
		// max-width: 300px;
		// margin: 0 20px 20px 0;
		// cursor: pointer;
	}
	.plan-heading {
		font-size: 50px;
		text-align: center;
		color: $light;
		margin-bottom: 20px;
	}
	.plan-sub-heading {
		text-align: center;
		font-size: 22px;
		color: $light;
		margin-bottom: 40px;
	}

	#new-plan-section {
		.sub-col .sub-table-head .sub-price {
			padding-top: 25px;
		}
	}

	.pm-card {
		background: #000;
		color: #ccc;
		display: inline-block;
		padding: 10px;
		margin: 0 20px 20px 0;
		border: 1px solid #ccc;
		cursor: pointer;
		p {
			margin: 10px 0;
		}
	}

	#add-paymethod-section {
		position: relative;
		div[trio-bank-card-form] {
			float: left;
		}
		.btn-default {
			position: absolute;
			left: 200px;
			bottom: 15px;
		}
	}


	// Checkout

	.oi-row {}

	.oi-name,
	.oi-amount,
	.oi-status {
		display: inline-block;
	}

	.oi-name {
		width: 200px;
		font-weight: bold;
	}
	.oi-amount { width: 100px; }
	.oi-status { width: 100px; }

}


// TODO
// http://blog.reactandbethankful.com/angular-multi-step-form/#/css-transitions?check=1
// https://docs.angularjs.org/api/ng/directive/ngSwitch