div[flx-channel-live],
a[flx-channel-live] {
	display: block;
	height: 75px;
	padding: 8px 10px 8px 80px;
	margin-bottom: 10px;
	background: transparent; //rgba(60, 60, 60, 0.5);
	border: 1px solid #555;
	border-radius: 3px;
	position: relative;
	line-height: 1.2;
	overflow: hidden;

	&:last-child {
		margin-bottom: 0;
	}
 
	&.active,
	&:hover {
		background: #333;
		border-color: #999;
		text-decoration: none;

		.simult-channel-name { color: #f8f8f8; }
		.simult-show { color: #eee; }
		.simult-desc {
			color: #aaa;
			&:after {
				background: #333;
			}
		}
	}

	img {
		position: absolute;
		left: 8px;
		opacity: 0.14;
		height: 56px;
	}
}

.simult-channel-name {
	color: #ccc;
	margin: 0 0 5px;
	font-size: 19px;
}

.simult-show {
	color: #999;
	margin: 0 0 4px;
	font-size: 15px;
	@extend %text-truncate;
}

.simult-desc {
	color: #555;
	margin: 0;
	@include multiline-text-truncate($font-size: 12px, $line-height: 1.1, $lines-to-show: 2, $excerpt-bg: #000);
}