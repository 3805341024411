trio-shows-carousel {
	display: block;
	position: relative;
}

.sc-view {
	overflow-x: auto;
}

.sc-list {
	position: relative;
	padding-bottom: 10px;
	white-space: nowrap;

	a:active {
		cursor: grabbing;
	}
}


.sc-prev,
.sc-next {
	position: absolute;
	top: 40%;
	padding: 10px;
	font-size: 40px;
	@include translate3d(0, -50%, 0);
	color: #fff;
	background: rgba(0, 0, 0, 0.2);
	@extend %noselect;
	&:hover {
		color: #fff;
		background: rgba(0, 0, 0, 0.8);
	}
}
.sc-prev {
	left: 0;
}
.sc-next {
	right: 0;
}

.sc-show {
	@extend %noselect;
}




