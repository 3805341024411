@import 'variables';


// Mixins

@mixin timeframe($left-position) {
	position: absolute;
	left: $left-position;
	top: 0;
	right: 0;
}

@mixin epg-row($height, $hour-width) {
	width: $epg-hours * $hour-width;
	height: $height;
}

@mixin time-mark($bg-color: $time-mark-color) {
	content: '';
	width: $epg-time-mark-width;
	display: block;
	background: $bg-color;
	position: absolute;
}


// Common classes

.epg-container {
	position: relative;
	@extend %noselect;
}

.epg-time-frame {
	@include timeframe($channels-width-lg);
}


// Head

.epg-head {
	width: 100%; // this is for confine="true" of ngsticky
	position: relative; // the child timeline needs it so it can get the parent height

	.epg-time-frame {
		background: transparent;
		overflow: hidden;

		div[no-sticky] & {
			right: 15px;
		}
	}

}

.epg-calendar {
	cursor: pointer;
	background: $time-mark-color;
	width: $channels-width-lg;
	height: $epg-row-height-lg;
	line-height: 1;

	&:hover {
		background: lighten($time-mark-color, 10%);
	}

	i {
		float: left;
		font-size: 25px;
		line-height: $epg-row-height-lg;
		padding: 0 10px;
	}
}

.epg-date {
	font-size: 18px;
	text-align: center;
	float: left;

	div {
		padding: 10px 0 5px;
		font-size: 14px;
	}
}


.epg-timeline {
	@include epg-row($epg-row-height-lg, $hour-width-lg);
	display: table;
	border-bottom: 2px solid $time-mark-color;

	.hour {
		display: table-cell;
		background: #111;
		width: $hour-width-lg;
		text-align: center;
		position: relative;
		vertical-align: bottom;
		line-height: 1;

		&:after {
			@include time-mark();
			height: 6px;
			bottom: 0;
			right: -1px;
			z-index: 1;
		}

		div {
			position: relative;
			padding-bottom: 5px;
			margin-bottom: 10px;

			&:after {
				@include time-mark();
				height: 10px;
				top: 100%;
				left: 50%;
				margin-left: 0; //-1px;
			}
		}
	}

	.epg-now-mark {
		text-align: center;
		line-height: 1;
		color: #fff;

		> div {
			position: absolute;
			top: 0;
			width: 42px;
			margin-left: -21px;
			padding-top: 5px;
			padding-bottom: 5px;
			background: #00aeef;
		}
	}
}


// Body

.epg-body {
	position: relative;
	padding-top: $epg-space-between-rows;

	.epg-time-frame {
		overflow: auto;
		padding-top: $epg-space-between-rows;
		z-index: 1;
	}
}

.epg-channels {
	width: $channels-width-lg;
}

.epg-channel {
	height: 0;
	padding-top: $epg-row-height-lg;
	text-indent: -9999px;
	line-height: 0;
	font-size: 0;

	margin-bottom: $epg-space-between-rows;
	background-color: #f8f8f8;
	background-repeat: no-repeat;
	background-position: center center;

	position: relative;

	&:hover {
		button {
			display: block;
		}
	}

	button {
		display: none;
		position: absolute;
		top: 5px;
		right: 5px;
		background: red;
		font-size: 11px;
		line-height: 1;
		color: #eee;
		border: 0;
		padding: 0 3px 2px;
		outline: 0;
	}
}

.epg-shows-row {
	@include epg-row($epg-row-height-lg, $hour-width-lg);
	position: relative;
	margin-bottom: $epg-space-between-rows;
}

.epg-show {
	position: absolute;
	height: 100%;
	padding-left: $epg-space-between-rows - 1;
	display: block;
	color: #ccc;

	&:hover {
		color: #ccc;
		text-decoration: none;
	}

	&:active {
		cursor: grabbing;
	}
}


@mixin epg-show-body-bg($background) {
	background: $background;

	&:hover {
		background: darken($background, 5%);

		div {
			@include multiline-text-truncate-bg(darken($background, 5%));
		}
	}

	div {
		@include multiline-text-truncate-bg($background);
	}
}


.epg-show-body {
	height: 100%;
	overflow: hidden;
	padding: 5px;
	line-height: 1;

	i {
		display: none;
		margin-left: 5px;
		.epg-ultra-short & {
			margin-left: 0;
		}
	}

	&:hover {
		i {
			display: inline;
		}
	}

	div {
		margin-top: 5px;
		line-height: 1.2;
		@include multiline-text-truncate($font-size: 14px, $line-height: 1.2, $lines-to-show: 2, $excerpt-bg: #111);
	}

	.epg-rec & {
		@include epg-show-body-bg($past-show-color);
	}

	.epg-live & {
		@include epg-show-body-bg($live-show-color);
	}

	.epg-future & {
		background: transparent;
		border: 1px solid #444;

		&:hover {
			background: #111;
		}
	}
}

.epg-now-mark {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	width: $epg-time-mark-width;
	background: #00aeef;
	z-index: 999;
}

.epg-prev,
.epg-next {
	position: absolute;
	top: $epg-row-height-lg;
	width: 80px;
	height: 200px;
	z-index: 2;
	color: #ccc;
	background: rgba(0,0,0,0.5);

	&:hover {
		background: rgba(0,0,0,0.8);
		color: orange;
	}

	&:before {
		position: absolute;
		font-family: 'FontAwesome';
		top: 50%;
		font-size: 40px;
		margin-top: -20px;
		line-height: 1;
	}
}

.epg-prev {
	left: $channels-width-lg;
	&:before {
		left: 15px;
		content: "\f053";
	}
}

.epg-next {
	right: 0;
	&:before {
		right: 15px;
		content: "\f054";
	}
}


/* Responsive styles
-------------------------------------------------------------- */


@mixin epg-media-sizes($m-channels-width, $m-row-height, $m-hour-width) {
	.epg-time-frame,
	.epg-prev {
		left: $m-channels-width;
	}

	.epg-calendar {
		width: $m-channels-width;
		height: $m-row-height;
	}

	.epg-timeline,
	.epg-shows-row {
		@include epg-row($m-row-height, $m-hour-width);
	}

	.epg-timeline {
		.hour {
			width: $m-hour-width;
		}
	}

	.epg-channels {
		width: $m-channels-width;
	}

	.epg-channel {
		padding-top: $m-row-height;
	}
}


// max 991px
@media (max-width: $screen-sm-max) {

	@include epg-media-sizes($channels-width-sm, $epg-row-height-sm, $hour-width-sm)

	.epg-calendar {
		i {
			padding: 0 8px;
		}
	}

	.epg-prev,
	.epg-next {
		top: $epg-row-height-sm;
	}

}

// max 767px
@media (max-width: $screen-xs-max) {

	@include epg-media-sizes($channels-width-xs, $epg-row-height-xs, $hour-width-xs)

	.epg-calendar {
		i {
			display: none;
		}
	}

	.epg-channel {
		background-position: 5px center;
	}

	.epg-prev,
	.epg-next {
		display: none;
	}

}

// max 479px
@media (max-width: $screen-xxs-max) {

	@include epg-media-sizes($channels-width-xxs, $epg-row-height-xxs, $hour-width-xxs)

	.epg-calendar {

	}

	.epg-channel {
		background-position: left center;
	}
}
