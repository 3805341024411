.player-foot {
	font-size: 18px;
	color: #555;
	cursor: default;
	font-size: 12px;
	line-height: 1.2;
	padding-top: 10px;

	h2 {
		margin: 0 0 5px;
		font-size: 20px;
		font-weight: 400;

		strong {
			color: #ccc;
		}
	}
}

.player-channel-logo {
	opacity: 0.2;
}

.player-desc {
	display: block;
	font-size: 12px;
	padding-right: 10%;
}

.player-next-show {
	h2 {
		// font-size: 20px;

		strong {
			color: #999;
		}
	}
}


/* Responsive styles
-------------------------------------------------------------- */

@media (min-width: $screen-sm-min) {

}

/* Medium devices (desktops, 992px and down) */
@media (max-width: $screen-md-max) {

	.player-foot {
		h2 {
			font-size: 20px;
		}
	}

}

/* Small devices (tablets, 768px and down) */
@media (max-width: $screen-xs-max) {

	.player-channel-logo,
	.player-desc {
		margin-bottom: 10px;
	}

}

/* Extra small devices (phones, less than 480px) */
@media (max-width: $screen-xs-min) {

}