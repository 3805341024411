
.co-btn {
	text-transform: none;
	padding: 10px 30px;
	display: none;
	border: 0;
	background: $link-color;
	color: $light;
	font-size: 24px;
	margin-top: 20px;
}

.plans-row-labels,
.plans-row-col {
	color: #FFF;
	float: left;
	padding-left:0;
	padding-right:0;
	.plans-table-row {
		white-space: nowrap;
		line-height: 32px;
		height: 32px;
		overflow: hidden;
		padding: 0 15px;
		background: #000;

		&:nth-child(2n){
			background: #333;
		}
	}
	.plans-offset-top {
		margin-top: 200px;
	}

	.plans-table-foot {
		line-height: 38px;
		height: 68px;
		
		border-top: 1px solid #ccc;

		background: rgb(216,216,216); /* Old browsers */
		background: -moz-linear-gradient(top,  rgba(244,244,244,1) 0%, rgba(216,216,216,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#d8d8d8',GradientType=0 ); /* IE6-9 */

		.btn-subscribe {
			color: $text-color;
			font-size: 17px;
			line-height: 38px;
			padding: 0 16px;
			border-radius: 3px;
			border: 1px solid #b7b7b7;

			background: rgb(216,216,216); /* Old browsers */
			background: -moz-linear-gradient(top,  rgba(244,244,244,1) 0%, rgba(216,216,216,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#d8d8d8',GradientType=0 ); /* IE6-9 */

			&:hover {
				color: $text-color;
				border: 1px solid rgb(190,190,190);

				background: rgb(226,226,226); /* Old browsers */
				background: -moz-linear-gradient(top,  rgba(226,226,226,1) 0%, rgba(206,206,206,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(206,206,206,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(226,226,226,1) 0%,rgba(206,206,206,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8d8d8', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */
			}
		}
	}
}
