a[flx-show-thumb] {
	display: inline-block;
	width: 288px;
	margin-right: 10px;
	position: relative;
	border: 3px solid transparent;

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		background-color:#252525;
		text-decoration: none;
		border: 3px solid #252525;
		transition: all 0.3s ease-in-out;

		.st-hover {
			display: block;
		}

		.st-duration {
			background: rgba(0, 0, 0, 0.8);
			border: 1px solid  rgba(255, 255, 255, 0.7);
		}
	}

	.st-cover {
		height: 162px;
	}

	.st-info {
		position: relative;
		padding: 10px;
		color: $text-color;
		font-size: 14px;
		line-height: 1.1;
	}

	.st-title {
		color: #ccc;
		@extend %text-truncate;
	}

	.st-channel {
		font-weight: block;
	}

	.st-time {
		font-size: 12px;
	}

	.st-duration {
		position: absolute;
		right: 0;
		bottom: 100%;
		padding: 2px;
		margin: 10px;
		color: #fff;
		line-height: 1;
		font-size: 12px;
		border: 1px solid transparent;
	}

	.st-hover {
		display: none;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		color: #ccc;
		font-size: 72px;
		position: relative;

		i {
			@include absolute-center();
		}
	}

}
