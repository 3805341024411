.dragscroll {
	// cursor: ew-resize;
	cursor: grab;
	&:active {
		cursor: grabbing;
	}
}

.is-dragging {
	pointer-events: none;
}