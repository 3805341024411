#schedule-page {

	div[trio-epg] {
		margin-bottom: 70px;
	}

	.epg-channel {
		background-size: auto 50px;
	}

	#epg-ch-573c569071c1b3f540471ea8 {
		background-size: 140px auto;
	}

	#epg-ch-573c569071c1b3f540471eac {
		text-align: center;
		height: $epg-row-height-lg;
		padding-top: 0;
		text-indent: 0;
		line-height: $epg-row-height-lg;
		font-size: 20px;
		color: red;
		font-weight: bold;
	}

	// max 767px
	@media (max-width: $screen-xs-max) {

		div[ui-view="content"] {
			.container-fluid {
				padding-left: 1px;
				padding-right: 1px;
			}
		}
	}
}