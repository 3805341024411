@mixin flx-player($foot-height) {

	overflow: hidden;

	.player-video {}

	.player-foot {}


	/* Responsive styles
	-------------------------------------------------------------- */

	/* Medium and large devices (768px and up) */
	@media (min-width: $screen-sm-min) {

		height: 100%;
		padding-bottom: $foot-height;

		.player-video {
			height: 100%;
		}

		.player-foot {
			height: $foot-height;
		}

	}

}


div[flx-player] {
	@include flx-player(100px);
}