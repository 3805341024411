/* Subscription Categories
-------------------------------------------------------------- */
.c-sub-btn {
		border: 0;

			background: $link-color;
			font-size:19px;
		}

.col-labels,
.sub-col {
	color: $dark;
	float: left;

	.sub-table-row {
		white-space: nowrap;
		line-height: 32px;
		height: 32px;
		overflow: hidden;
		padding: 0 15px;
		background: #fff;

		&:nth-child(2n){
			background: #ebebeb;
		}
	}
	.row-offset-top {
		margin-top: 200px;
	}

	.sub-table-foot {
		line-height: 38px;
		height: 68px;
		padding: 15px 15px;
		border-top: 1px solid #ccc;

		background: rgb(216,216,216); /* Old browsers */
		background: -moz-linear-gradient(top,  rgba(244,244,244,1) 0%, rgba(216,216,216,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#d8d8d8',GradientType=0 ); /* IE6-9 */

		.btn-subscribe {
			color: $text-color;
			font-size: 17px;
			line-height: 38px;
			padding: 0 16px;
			border-radius: 3px;
			border: 1px solid #b7b7b7;

			background: rgb(216,216,216); /* Old browsers */
			background: -moz-linear-gradient(top,  rgba(244,244,244,1) 0%, rgba(216,216,216,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(244,244,244,1) 0%,rgba(216,216,216,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#d8d8d8',GradientType=0 ); /* IE6-9 */

			&:hover {
				color: $text-color;
				border: 1px solid rgb(190,190,190);

				background: rgb(226,226,226); /* Old browsers */
				background: -moz-linear-gradient(top,  rgba(226,226,226,1) 0%, rgba(206,206,206,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(206,206,206,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(226,226,226,1) 0%,rgba(206,206,206,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8d8d8', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */
			}
		}
	}
}

.col-labels {}

.sub-col {
	text-align: center;

	.sub-table-head {
		height: 200px;
		color: $light;
		position: relative;

		.sub-name {
			font-size: 24px;
			border-bottom: 1px solid rgba(255,255,255,0.2);
			line-height: 50px;
			display: block;
			width: 100%;
		}

		.sub-badge {
			font-size: 12px;
			line-height: 15px;
			height: 15px;
			display: inline-block;
			padding: 0 12px;
			position: absolute;
			top: 52px;
			right: 0;

			&:before{
				width: 0;
				height: 0;
				display: block;
				position: absolute;
				top: 0;
				left: -16px;
				content: '';
				border-style: solid;
				border-width: 0 16px 15px 0;
				border-color: transparent #000 transparent transparent;
			}
		}

		.sub-price {
			padding-top: 14px;
			font-size: 17px;
			border-top: 1px solid rgba(0,0,0,0.2);
			display: block;
			width: 100%;

			strong {
				display: block;
				font-size: 60px;
				font-weight: 400;
				line-height: 1;

				span{
					color: $main-color;
					opacity: 0.25;
				}
				small {
					font-size: 25px;
					display: inline-block;
					position: relative;
					top: -1em;
				}
			}
		}
	}

	.sub-table-row {
		.fa {
			font-size: 24px;
			line-height: 32px;
		}

		.label-mobile {
			display: none;
		}
	}

	&.recommended-plan {
		margin-top: -14px;

		.sub-table-head {
			height: 214px;
		}

		.sub-table-foot {
			height: 82px;
			padding-top: 20px;

			.btn-subscribe {
				color: $light;
				background: $link2-color;
				border-radius: 4px;
				border: 1px solid rgb(150,150,150);

				&:hover{
					color: $text-color;
					border: 1px solid rgb(190,190,190);

					background: rgb(226,226,226); /* Old browsers */
					background: -moz-linear-gradient(top,  rgba(226,226,226,1) 0%, rgba(206,206,206,1) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(206,206,206,1) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom,  rgba(226,226,226,1) 0%,rgba(206,206,206,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8d8d8', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */
				}
			}

		}
	}
}

.plan-col {
	.sub-table-head {
		overflow: hidden;

		.sub-badge {
			transform: rotate(45deg);
			padding: 0 30px;
			top: 24px;
			right: -32px;
		}
	}
}

/* Subscription Categories Colors
-------------------------------------------------------------- */

// Red
.sub-cat-web {
	.sub-table-head {
		background: $link-color;

		.sub-badge {
			background: $link2-color;
			&:before{
				border-color: transparent $link2-color transparent transparent;
			}
		}
	}
}

// Green
.sub-cat-web-tv {
	.sub-table-head {
		background: $link2-color;

		.sub-badge {
			background: $link-color;
			&:before{
				border-color: transparent $link-color transparent transparent;
			}
		}
	}
}


/* Responsive styles
-------------------------------------------------------------- */

/* Small devices (tablets, 768px and down) */
@media (max-width: $screen-sm) {

	.col-labels {
		display: none;
	}

	.sub-col {
		margin-top: 0;
		margin-bottom: 30px;

		&.recommended-plan {
			margin-top: -14px;
		}

		.sub-table-row {
			height: auto;
			overflow: visible;

			.label-mobile {
				display: block;
			}
		}
	}

} // @media (max-width: $screen-sm-max)

@media (max-width: 550px) {
	.sub-col {
		width: 100%;

		&.recommended-plan {
			.sub-table-head {
				height: 200px;
			}
			.sub-table-foot {
				height: 68px;
				padding-top: 15px;
			}
		}
	}
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: $screen-xs) {

	.col-labels {
		display: none;
	}

	.sub-col {

		.sub-table-row {
			height: auto;
			overflow: visible;

			.label-mobile {
				display: block;
			}
		}
	}

} // @media (max-width: $screen-xs-max)
