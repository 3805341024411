#selected-page {

	&.modal-open {
		#main-nav {
			right: 0;
		}
	}

	h3 {
		font-size: 20px;
		color: $light;
		margin-bottom: 30px;
	}

	trio-shows-carousel {
		margin-bottom: 30px;
	}


	/* Responsive styles
	-------------------------------------------------------------- */

	/* Small devices (tablets, 768px and down) */
	@media (max-width: $screen-xs-max) {
		h3 {
			font-size: 18px;
		}
	}

}
