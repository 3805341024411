%text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


// http://revelry.co/multi-line-ellipsis-using-pure-css/
// http://dev.mobify.com/blog/multiline-ellipsis-in-pure-css/
// http://codepen.io/romanrudenko/pen/ymHFh
// http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
@mixin multiline-text-truncate($font-size, $line-height, $lines-to-show, $excerpt-bg) {
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
	font-size: $font-size;
	line-height: $line-height;
	-webkit-line-clamp: $lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	@-moz-document url-prefix() {
		// background: $excerpt-bg;
		overflow: hidden;
		position: relative;
		&:before {
			// background: $excerpt-bg;
			position: absolute;
			bottom: 0;
			right: 0;
			// float: right;
			content: '\2026';
			// margin-left: -3rem;
			// width: 3rem;
		}
		&:after {
			content: '';
			background: $excerpt-bg;
			position: absolute;
			right: 0;
			height: 1.1em;
			width: 1em;
			z-index: 1;
		}
	}
}

// For changing the color just of the :after box
@mixin multiline-text-truncate-bg($excerpt-bg) {
	@-moz-document url-prefix() {
		&:after {
			background: $excerpt-bg;
		}
	}
}

