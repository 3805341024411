@mixin full-window-page() {

	div[ui-view="main"] {
	}

	div[ui-view="content"] {
		padding: 10px;
	}

	/* Responsive styles
	-------------------------------------------------------------- */

	/* Medium and large devices (768px and up) */
	@media (min-width: $screen-sm-min) {
		div[ui-view="main"] {
			height: 100%;
		}

		div[ui-view="content"] {
			height: 100%;
		}
	}

}